.footer-logo-row {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 5vw 15vw;
  @media(min-width: 1280px){
     max-width: 900px;
     margin: 5rem auto;
  }
}
.footer-logo-icon {
  width: 100%;
}


.footer__text{
  margin-bottom: 2rem;
}

.footer__colWrapper{
  max-width: 33.3%;
}

.foooter__col{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 1rem;
}

.footer-media-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-left: 30vw;
  margin-right: 30vw;
  margin-bottom: 5vw;
}
.footer-media-icon {
  width: 3vw;
}

.footer-media-icon--small{
  max-width: 50px;
}

.footer__button{
  display: block;
}

.footer__button:hover{
  transform: scale(1.1);
  transition: 0.5s;
}

@media (max-width: 1023px){
  .foooter__col{
    margin: 0;
  }
}

@media (max-width: 767px) {
  .footer-logo-row {
    margin-left: 7vw;
    margin-right: 7vw;
    margin-bottom: 5vw;
  }

   .foooter__col{
    margin: 0;
    } 

  // .footer-logo-icon {
  //   width: 18vw;
  // }
  .footer-media-row {
    margin-left: 20vw;
    margin-right: 20vw;
    margin-bottom: 5vw;
  }
  .footer-media-icon {
    width: 4vw;
  }
}
@media (max-width: 500px) {
  .footer-logo-row {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 9vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

   .foooter__col{
      width: 70%;
      margin: 1rem 0;
      display: block;
    } 


  .footer-logo-icon {
    width: 100%;
  }

  .footer-media-row {
    margin-left: 4vw;
    margin-right: 4vw;
    margin-bottom: 9vw;
  }
  .footer-media-icon {
    width: 8vw;
  }
}