.blog-font {
  font-family: "MonoType", serif;
}
.blog-header {
  width: 100%;
    margin-bottom: 3rem;
}
.blog-title {
  font-size: 3rem;
  text-transform: uppercase;
  justify-content: center;
  margin: 0;
  text-align: center;
}



  .blog-description {
    font-size: 1.5rem;
    text-align: justify;
  }

@media(min-width:1024px){
  .blog-header {
    margin-bottom: 4rem;
  }
 .blog-description {
    font-size: 2rem;
  }
}

@media (max-width: 600px) {
  .blog-title {
    font-size: 2rem;
  }
  .blog-header{
    margin-bottom: 1rem;
  }
  .blog-description {
    font-size: 1rem;
    margin-right: 5vw;
    margin-left: 5vw;
  }
}