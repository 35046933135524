.contactFun__container{
    display: flex;
    flex-direction: column;
}

.contactFun__wrapper{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    gap: 5px;
    align-items: flex-start;
    @media(min-width: 768px){
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }
}