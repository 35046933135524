.blogs-loading {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5vw;
  background-color: #7345a9;
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  @media(min-width:768px){
      font-size: 2rem;
  }
  @media(min-width:1024px){
    font-size: 3rem;
  }
}

.font-only {
  font-family: "MonoType", serif;
}

.blogs-placeholder-square {
  width: 10vw;
  height: 10vw;
  background-color: #7345a9;
}

.blogs-category {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
  width: 100%;
}

.blogs-category-button {
  border-radius: 0;
  font-size: 1rem;
  display: grid;
  margin-top: 1rem;
}
.blogs-title {
  font-size: 2rem;
  text-align: center;
}
.blogs-description {
  font-size: 1.3rem;
  text-align: justify;
}

.blogs-category-img{
  position: absolute;
  height: 200%;
}

.blogs__buttonsWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media(min-width: 768px){
  .blogs-category {
    flex-direction: row;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .blogs__buttonsWrapper{
    display: flex;
    flex-direction: row;  
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
  }

  .blogs-category-button {
    padding: 0.5rem;
    margin-top: 0;
    margin-left: 1rem;
  }

  .blogs-title{
    text-align: start;
  }
}

// @media(max-width: 767px) {
//   .blogs-category {
//     margin: 0 2vw 5vw;
//   }
//   .blogs-category-button {
//     border-radius: 0;
//     font-size: 8vw;
//     width: 80vw;
//     height: 16vw;
//     display: grid;
//   }
//   .blogs-title {
//     font-size: 8vw;
//   }
//   .blogs-description {
//     font-size: 4vw;
//     margin-top: 3vw;
//     margin-bottom: 3vw;
//   }
//   .blogs-edit-button {
//     margin: 3vw;
//     border-radius: 0;
//     font-size: 8vw;
//     width: 80vw;
//     height: 26vw;
//     display: flex;
//     justify-content: center;
//     align-content: center;
//   }
// }