.offerApplication__container{
    display: flex;
    flex-direction: column;

    @media(min-width: 1024px){
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
}

.offerApplication__btnContainer{
    display: flex;
    flex-direction: column;

    @media(min-width: 768px){
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
  
}

.offerApplication__button{
    white-space: nowrap;
        margin-bottom: 0.5rem;
    @media(min-width: 768px){
        margin-right: 0.5rem;
        margin-bottom: 0;
    }
}

.offerApplication__button--CV{
    margin: 0.5rem 0;

    @media(min-width: 768px){
      margin: 0.5rem 0.5rem 0.5rem 0;
    }

    @media(min-width: 1024px){
      margin: 0 0.5rem;
    }
}

.offerApplication__button--delete{
    @media(min-width: 1024px){
        align-self: stretch;
    }
}