.videoField__container {
  height: 100%;
  width: 100%;
  position: relative;
  min-height: 100px;

  & div:first-child{
    position: relative;
    padding-bottom: 56.25%;
  }
}

.videoField__containerWithoutVideo{
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.videoField__video{
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

