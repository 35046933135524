.removeOffer {
  text-align: center;

  .alert {
    margin-bottom: 0;
  }
}

.JobOffer__logo{
  max-width: 6rem;
}

.jobOfferDetails__description{
  white-space: pre-wrap;
  text-align: justify;
}