.aboutProject__container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aboutProject__section{
    text-align: justify;
    background-color: white;
    padding: 0 1rem;
}

.aboutProject__section:first-child{
    padding-top: 1rem;
}

.aboutProject__section:last-child{
    padding-bottom: 1rem;
}


.aboutProject__header{
    background-color: white;
}

.aboutProject__list{
    margin-top: 1rem;
    list-style-type: none;
    background-color: white;
}

.aboutProject__wrapper{
    display: flex;
    flex-direction: column;
       @media(min-width: 1280px){
        flex-direction: row-reverse;
    }
}

.aboutProject__colWrapper{
    
    @media(min-width: 1280px){
        width: 40%;
        margin-right: 1rem;
    }
}

.aboutProject__contentImg{
    margin-bottom: 1rem;
    @media(min-width: 1280px){
        padding-right: 1rem;
        display: block;
        width:60%;
        min-width: 0;
    }
}
