.Menu {
  --window-unit: calc((1vh + 0.56vw) / 2);
  background-color: white;

  .mainPhoto {
    display: flex;
    position: relative;
    width: 100%;
    display: flex;
    max-height: 100vh;
    justify-content: center;
    align-items: center;
  }

  .tvContainer {
    position: relative;
    width: 100%;
    padding-bottom: 3rem;
    margin-top: -2.5rem;
    @media(min-width: 768px){
      margin-top: -4rem;
    }
    @media(min-width: 1024px){
      margin-top: -4.5rem;
    }
      @media(min-width: 1440px){
      margin-top: -5rem;
    }
  }

  .tvContainer__shapes {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 3rem;
    justify-content: space-between;
  }

  .shapes__image {
    height: 100%;
    object-fit: contain;
  }

  .shapes__image--left {
    width: 100%;
    object-position: left;
  }

  .shapes__image--right {
    object-position: right;
    width: auto !important;
  }


  .tvContainer__shapes__left {
    flex: 1 0 auto;
    width: 50%;
    padding-right: 2rem;
    transform: translateX(-5vw);
    margin-top: -1rem;
    margin-bottom: 1rem;

}



  .tvContainer__shapes__right {
    flex: 1 0 auto;
    width: 50%;
    // padding-left: 6rem;
    display: flex;
    justify-content: flex-end;
    margin-left: 5vw;
    margin-top: 2rem;
    margin-bottom: -2rem;

  }

  .tvContainer__tv {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 1rem calc(1rem + 10vw);
  }

  .tvContainer__tv__img {
    // min-width: 300px;
    // width: 100%;

    height: 100%;
    width: 100%;
    max-width: 1000px;
    object-fit: contain;
  }

  .tvContainer__tv__video__container {
    display: flex;
    justify-content: center;
    position: absolute;
    height: calc(100% - 2rem);
    width: calc(100% - 2rem - 20vw);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
  }

  .tvContainer__tv__video {
    margin-top: 1rem;
    height: calc(100% - 2rem);
    max-width: 1000px;
    width: calc(100% - 20vw);
    width: 100%;
  }

  .tvContainer__tv__video__space {
    padding: 7% 18% 13% 5.7%;
    height: 100%;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .videoField__container {
    height: auto;
  }




  .tilesGrid__container {
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: "MonoType", serif;


    .tilesGrid {
      --tile-unit: var(--window-unit);
      --tile-height: calc(50 * var(--tile-unit));
      --tile-width: calc(0.75 * var(--tile-height));
      --tiles-gap: calc(var(--tile-unit) / 2 + 0.3rem);
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: calc((4 * var(--tiles-gap)) + (3 * var(--tile-width)));
      padding: var(--tiles-gap) 0 0 var(--tiles-gap);
      background-color: #fff;

      .tile__button {
        &__container {
          position: absolute;
          z-index: 10;
          right: 0;
        }

        &__edit {
          margin-right: 0.25rem;
        }

        &__delete {
          margin-left: 0.25rem;
        }
      }

      .tile__container {
        display: flex;
        flex: 1 0 auto;
        justify-content: center;
        height: var(--tile-height);
        min-width: var(--tile-width);
        margin: 0 var(--tiles-gap) var(--tiles-gap) 0;
        position: relative;
        .tile {
          display: grid;
          grid-template:
            "bg-top bg-top bg-top"1fr "bg-left content bg-right"8fr ". . ."1fr / 1fr 8fr 1fr;
          height: var(--tile-height);
          width: var(--tile-width);

          .tile_content {
            --ctn-border-size: calc(var(--tile-unit) / 2 + 0.3rem);
            --ctn-title-size: calc(var(--tile-unit) * 3 + 2.5rem);
            grid-area: content;
            display: grid;
            grid-template:
              "border-left border-top border-right"var(--ctn-border-size) "border-left image border-right"calc(0.8 * var(--tile-height) - var(--ctn-border-size) - var(--ctn-title-size)) "title title title"var(--ctn-title-size) / var(--ctn-border-size) 1fr var(--ctn-border-size);
          }

          .tile__image {
            --img-mg-top: calc(-0.09 * var(--tile-height));
            --img-mg-sides: calc(-0.09 * var(--tile-width));
            grid-area: image;
            margin: var(--img-mg-top) var(--img-mg-sides) 0;
            position: relative;
            display: flex;
            align-items: stretch;
            z-index: 2;
            height: calc(100% - var(--img-mg-top));
            width: calc(100% - 2 * var(--img-mg-sides));

            img {
              width: 100%;
              object-fit: contain;
              object-position: bottom;
              transition: transform .2s ease;
            }
          }

          .tile_title {
            grid-area: title;
            display: flex;
            align-items: center;
            justify-content: center;
            border: solid var(--ctn-border-size) #fff;
            transition: background-color .2s ease;

            span {
              text-align: center;
              line-height: calc(0.8rem + (0.8 * var(--tile-unit)));
            }
          }

          .tile__border {
            &--left {
              grid-area: border-left;
            }

            &--top {
              grid-area: border-top;
            }

            &--right {
              grid-area: border-right;
            }
          }

          .tile__bg {
            position: relative;
            z-index: 3;

            &--left {
              grid-area: bg-left;
            }

            &--top {
              grid-area: bg-top;
            }

            &--right {
              grid-area: bg-right;
            }
          }

          .tile__border,
          .tile_title {
            background-color: white;
            position: relative;
            z-index: 3;
            text-transform: uppercase;
            color: #000;
            letter-spacing: 0.1rem;
            font-size: calc(0.4rem + (0.8 * var(--tile-unit)));
          }

          .tile__fragment {
            &--show {
              z-index: 1;
            }
          }
        }

        &:hover {
          .tile__image img {
            transform: scale(1.05);
          }

          .tile_title {
            color: initial;
            color: #000;
          }
        }
      }

      .tilesGrid__newTile {
        margin: 0 var(--tiles-gap) var(--tiles-gap) 0;
      }
    }

    .tilesGrid {
      justify-content: center;

      .tile__container {
        width: var(--tile-width);
      }
    }
  }
}


@media(min-width: 992px){
  .newTileForm__wrapper{
    display: flex;
    flex-direction: row !important;
    flex-wrap: nowrap;
  }

  .newTileForm__wrapper--item{
    width: 50%;
  }
}