.errorPage__container{
    width: 100%;
    height: calc(100vh - 72px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorPage__content{
    padding: 0 1rem;
    transform: translateY(-36px);
}

.errorPage__header{
    font-size: 1.1rem;
    color: #b5c91c;
    
    @media(min-width: 768px){
            font-size:2.5rem;
    }
       @media(min-width: 1024px){
            font-size:3rem;
    }
}

.errorPage__subheader{
    font-size: 1.1rem;

    
    @media(min-width: 768px){
            font-size:2rem;
    }
       @media(min-width: 1024px){
            font-size:2.5rem;
    }
}

.errorPage__li{
       font-size: 1rem;

    
    @media(min-width: 768px){
            font-size:1.5rem;
    }
       @media(min-width: 1024px){
            font-size:2rem;
    }
}

.errorPage__back{
    font-size:1.2rem;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;

    @media(min-width: 768px){
            font-size:2.5rem;
    }
     @media(min-width: 1024px){
            font-size:3rem;
    }
}