.blog-comments {
  margin: auto;
  display: flex;
  font-size: 1.3rem;
  font-weight: bold;
  flex-direction: column;
  background-color: white;
  width: 100%;
  margin-top: 2rem;
}

.comments__form{
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width:768px){
    flex-direction: row;
  }
}

.blog-comment-author{
  font-weight: bold;
}

.blog-comment-add {
  border-radius: 0;
}
.blog-comment-add-button {
  border-radius: 0;
  min-width: 160px;
}
.blog-comment-row {
  display: flex;
  flex-direction: column;
  padding: 5px 0 15px;
}
.blog-comment-author {
  font-size: 16px;
}
.blog-comment-content {
  font-size: 1rem;
  font-weight: lighter;
}
.blog-comment-time{
  font-weight: lighter;
}
.blog-comment-not-logged {
  font-size: 24px;
  color: gray;
  justify-content: center;
}

@media (max-width: 500px) {
  .blog-comments {
    width: 90vw;
  }
}