.property__title {
  width: 160px;
  font-weight: 600;
}

.buttonsContainer {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  & > .btn {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    &.disabled, &:disabled {
      cursor: not-allowed;
    }
  }
}
