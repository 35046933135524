.job-offers-filter-button {
  margin: 0.25rem 0 0.25rem;
  width: 60%;
  border-radius: 0;
  font-size: 1.2rem;
  width: 100%;
}
.job-offers-filter-text {
  font-size: 1.2rem;
}


.job-offers-filter {
  box-shadow: 1px 4px 19px 3px #ADADAD;
  background-color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
  @media(min-width: 768px){
    padding-bottom: 0rem;
  }
}


@media (max-width: 768px) {
  .job-offers-filter-text {
    font-size: 1rem;
  }
  .job-offers-filter-button {
    width: 100%;
    font-size: 1rem;
  }
}
