.border {
  border: gray;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.max-height {
  height: 90vh;
}
@media (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }
}
.mouse-hand-pointer {
  cursor: pointer;
}
.no-lrborder {
  border-left: none;
  border-right: none;
}
.not-read {
  background-color: #e6e6fa;
}
