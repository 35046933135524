.react-datepicker-wrapper {
  display: block;
}

.CVEditorPage_tabs {
  margin: 0 !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.CVEditorPage_tabs a{
  color: #c87c6f;
}

.list-inline-item{
  max-width: 100%;
  word-wrap: break-word;
}