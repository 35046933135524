.yt_player {
  //height: 100%;
  width: 100%;
  position: relative;

  & div:first-child {
    //height: 100%;
    width: 100%;
  }
}
