.chats__body {
  .alert {
    margin-bottom: 0;
  }
}
.chatInfo__picture {
  display: flex;
  align-items: center;
}

.contacts-modal {
  min-height: 90vh;
  justify-content: space-between;
}

.chat__form{
  min-height: 50px;
  max-height: 200px;
}

.search__form{
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
