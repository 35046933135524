@media (min-width: 768px){
.CVEditor__videoContainer{
  margin: 0 20%;
}
}

.form_navigation_next, .form_navigation_prev{
  background-color: #c87c6f;
  border: #c87c6f !important; 
} 

.form_navigation_next:hover, .form_navigation_prev:hover{
  background-color: #bd9088;
    border: #c87c6f !important; 
} 

.form_navigation_next:disabled, .form_navigation_prev:disabled{
  background-color: #c87c6f;
} 

.form_navigation_next:active:focus, .form_navigation_prev:active:focus{
  background-color: #bd9088 !important;
  border: #c87c6f !important; 
  box-shadow: none !important;
} 

@media(max-width:767px){
  .cv__navBottom{
    margin-bottom: 0.5rem;
  }
}