.postTile{
    position: relative;
    width: 100%;
    align-items: center;
    &:hover{
        cursor: pointer;
    }
}

.postTile__content{
    position: relative;
    z-index: 2;
}

.postTile__photoWrapper{
    position: relative;
}

.postTile__border{
    position: relative;
    z-index: 2;
}

.postTile__cover{
    position: absolute;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    padding: 0 12%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}


.postTile__shape{
    position: absolute;
    z-index: 0;
    width: 150%;
    left: -30%;
}

.postTile__header{
    font-size: 1rem;
}