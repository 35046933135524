$theme-colors: (
  "primary":#1DB954,
);

.card-header {
  text-align: center;
}

.btn{
  border-radius:0 !important;
}

body {
  margin: 0;
  font-family: "MonoType",serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
  font-weight: bold !important;
}

.padding__text{
  display: flex;
  flex-direction: column;
  align-items: center;
  @media(min-width: 768px){
    padding: 0 10%;
  }  
}

.descriptions{
  font-size: 1rem;
  text-align: justify;
  background-color: white;
  padding: 1rem;
  @media(min-width: 768px){
      font-size: 1.5rem;
      margin: 2rem 0;
  }
  @media(min-width: 1024px){
      margin: 3rem 0;
  }
}

.descriptions-cv {
  font-size: 1rem;
  text-align: justify;
  background-color: white;
  padding: 1rem;
  @media(min-width: 768px){
    font-size: 1.5rem;
    margin: 0 1rem 1rem;
  }
  @media(min-width: 1024px){
    margin: 0 1rem 1.5rem;
  }
}

.height-100{
  height: calc(100vh - #{$nav-marginM});
}

.center-container {
  // display: flex;
  min-height: calc(100vh - #{$nav-marginM});
  margin-top: $nav-marginM;

  overflow: hidden;
  // align-items: center;
  // padding: 2rem 0;
}
@media (min-width: 768px) {
  .center-container {
    min-height: calc(100vh - #{$nav-marginD});
    margin-top: $nav-marginD;
    width: 100%;
  }
}

.btn-blue {
  background-color: #53BDFD!important;
  color: white!important;
}
.btn-blue:hover {
  background-color: #4297CA!important;
  color: white!important;
}

.btn-outline-blue {
  background-color: white!important;
  color: #53BDFD!important;
  border-color: #53BDFD!important;
}
.btn-outline-blue:hover {
  background-color: #4297CA!important;
  color: white!important;
}

// @media (min-width: 1200px){
// .container, .container-sm, .container-md, .container-lg, .container-xl {
//     max-width: none !important;
// }
// }

@media (min-width: 1366px){
.container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1280px !important;
}
}