.tagSelector__wrapper{
    padding: 1rem;

    @media(min-width: 768px){
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
    }
}

.tagSelector__container{
    @media(min-width: 768px){
        width: 45%;
    }
}

.tagSelector__err{
    color: red;
}