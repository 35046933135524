@import "utilities/variables";
@import "bootstrap/custom";
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@import "pages/loginRegisterPage.scss";
@import "pages/offerForm.scss";
@import "pages/userProfilePage.scss";
@import "pages/menu";
@import "pages/headerLayout";
@import "pages/CVEditorPage";
@import "pages/blogPage";
@import "pages/steps";
@import "pages/CVCorrection";
@import "pages/jobOfferDetails";
@import "pages/messagesList";
@import "pages/chats";
@import "pages/changeData";
@import "pages/jobOffersPage";
@import "pages/footer";
@import "pages/header";
@import "pages/blog";
@import "pages/blogs";
@import "pages/offerApprovalPage";
@import "pages/blogCreator";
@import "pages/askExpert";
@import "pages/errorPage";
@import "pages/blogPost";
@import "pages/contactPage";
@import "pages/generateApiKeys";
@import "pages/workshopPost";
@import "pages/aboutProject";
@import "pages/contactFoundation";
@import "pages/confirmUser";

@import "components/detailsItem";
@import "components/CVEditorTab";
@import "components/footer";
@import "components/filter";
@import "components/notifications";
@import "components/notificationItemContainer";
@import "components/smallBlogPost";
@import "components/alertMessage";
@import "components/formGroup";
@import "components/userPicture";
@import "components/videoField";
@import "components/cookiesModal";
@import "components/blogPosts";
@import "components/blogPost";
@import "components/categoryTile";
@import "components/tagSelector";
@import "components/treeOfSelectors";
@import "components/expertsCreator";
@import "components/postTile";
@import "components/expert";
@import "components/blogComments";
@import "components/deleteCommentButton";
@import "components/jobOfferInfo";
@import "components/offerApplication";
@import "components/myOffersPerson";
@import "components/calendar";

@import "components/player";
@import "components/newTileForm";
