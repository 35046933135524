.job-offers-offer {
  width: 100%;
  background: #f7f7f7;
  margin-top: 2rem;
  box-shadow: 1px 4px 19px 3px #ADADAD;
  // box-shadow: 1px 4px 15px 5px #000000;
  // box-shadow: 1px 4px 9px 0px #000000;
  padding: 1rem 1rem 1rem 1rem;
  @media(min-width: 768px){
    padding: 1rem 1rem 1rem 0;
  }

}

.job-offers-offer-companyName {
  font-size: 1rem;
}
.job-offers-offer-description {
  font-size: 1rem;
}
.job-offers-offer-salary {
  font-size: 1rem;
  color: #53BDFD;
}
.job-offers-offer-salary:hover {
  color: #4297CA;
}

.job-offers-offer-button-background {
  background-image: url("~assets/backgrounds/job-offer-button-2.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: center;
}
.job-offers-offer-button-background:hover {
  background-image: url("~assets/backgrounds/job-offer-button-hover.svg");
  cursor: pointer;
}
.job-offers-offer-button {
  padding: 1rem;
  font-size: 1rem;
  color: white;
  //background-color: #53BDFD;
}

.jobOffer__offerTitle{
  font-size: 1.2rem;
  text-align: justify;
}

@media(min-width: 1200px){
  .jobOffer__offerTitle{
    font-size: 1.8rem;
  }
  .job-offers-offer-button {
    font-size: 1.3rem;
  }

  .job-offers-offer-companyName {
  font-size: 1.5rem;
}
.job-offers-offer-description {
  font-size: 1.2rem;
}
.job-offers-offer-salary {
  font-size: 1.2rem;
  color: #53BDFD;
}
}

@media (max-width: 768px) {
  .job-offers-offer-companyName {
    font-size: 1rem;
  }

  .jobOffer__offerTitle{
    font-size: 1rem;
    text-align: justify;
  }

  .job-offers-offer {
    width: 100%;
    padding: 1rem 1rem 1rem 8px;
    margin-top: 2rem;
  }
  .job-offers-offer-name {
    font-size: 1rem;
  }
  .job-offers-offer-description {
    font-size: 0.8rem;
  }
  .job-offers-offer-salary {
    font-size: 0.8rem;
  }

  .job-offers-offer-button-background {
    min-height: 50px;
    margin-top: 10px;
  }
  .job-offers-offer-button {
    padding: 0.5rem;
    font-size: 0.8rem;
  }
}