.categoryChooser__header.card-header{
    display: flex;
    justify-content: center;
    align-items: center
}




.categoryTile__wrapper{
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    column-gap: 15px;
    row-gap: 15px;
    @media(min-width: 768px){
        padding: 10px;
    }
}

.categoryTile{
    overflow: hidden;
    max-width: 320px;
    border: 10px solid #a63a53;
    background-color: #a63a53;
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.categoryTile--center{
    align-self:center;
}

.categoryTile__imgContainer{
    width: 100%;    
    border-bottom:10px solid #a63a53;
}

.categoryTile__img{
  width: 100%;
}

.categoryTile__choosen{
 background:rgba(120,214,101,0.5);
 width:100%;
 height:100%;
 position:absolute;
 left:0px;
 top:0px;
 z-index:2;
}

.categoryTitle__choosen--disable{
    display: none;
}

.categoryTile__content{
    background-color: #a63a53;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    flex-basis: 100%;
}