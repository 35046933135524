.ask-expert-container {
  font-family: "MonoType", serif;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.ask-expert-banner {
  align-self: center;
  text-align: center;
  color: white;
  background-color: #1D3396;
  padding: 2rem;
  font-size: 2rem;

}

.ask-expert-experts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .ask-expert-banner {
    width: 90%;
    font-size: 1.5rem;
    padding:1rem;
  }
}