.contactList__wrapper{
    margin-top: 2rem;
}

.contact__container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}

.contact__wrapper{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media(min-width: 768px){
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.contact__logo{
    max-width: 100%;
    @media(min-width: 768px){
        max-width: 50%;   
        margin-right: 1rem;
    }
}

.contact__header__title{
    font-size: 1.5rem;
    @media(min-width: 768px){
        font-size: 2rem;
    }

    @media(min-width: 1024px){
        font-size: 3rem;
    }
}

.contact__number{
    display: flex;
    align-items: center;
    flex-direction: column;
}

.contact__phone_number{
    font-weight: bold;
    letter-spacing: 1px;
    font-size:2rem;
    color: theme-color("primary");
    margin-left: 0.5rem;
}

.contact__header{
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 1rem;
    @media(min-width: 768px){
        margin-top: 0;
        max-width: calc(50% - 1rem);
    }

}

.contact__description{
    margin-top: 0.5rem;
    margin-bottom: 0;
    text-align: justify;
    @media(min-width: 768px){
        font-size: 1.5rem;
    }
}

.contact__button{
    border-radius: 0;
    padding: 0.5rem;
    @media(min-width: 768px){
         padding: 1rem;
    }
  
}