
.steps-choice-div {
    background-image: url("~assets/shapes/choice-2.svg");
    //background-size: cover;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    /*min-width: 320px;
    min-height: 160px;*/

    padding: 2.5rem;
    margin: 1rem 3rem 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-transform: uppercase;
    text-align: center;
    color: white;
    font-size: 1.75rem;

    cursor: pointer;
}
.align-flex-end {
    align-items: flex-end;
}
.steps-choice-edit-button {
    text-transform: uppercase;
    background-color: #295E7E;
    border-radius: 0;
    border-color: #295E7E;

    //width: 20vw;
    //min-height: 40px;
    //margin: 10px 50px 10px;

    font-size: 1.2rem;
    padding: 0.5rem 1rem 0.5rem;
    //margin: 1rem;
    margin: auto 1rem auto;
}

.steps__step{
    @media(min-width: 768px){
        margin-bottom: 0;
    }
}

.steps-step-card {

    // background-image: url("~assets/shapes/step-background-2.svg");
    // background-size: cover;
    padding: 3.5vw;
}
.steps-step-media {
    max-width: 100%;
    max-height: 100%;
}
.steps-step-title {
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 40px;
    text-transform: uppercase;
}
.steps-step-description {
    color: white;
    font-size: 22px;
    width: 65%;
}
.steps-line-img {
    height: 25vh;
}
.steps-line {
    background-color: #53BDFD;
    height: 48%;
    margin: 0 auto 0;
    width: 11px;
}
.steps-dot {
    width: 20px;
    height: 20px;
    margin: 5% auto 5%;
    background: #53BDFD;
    border-radius: 50%
}
.steps-choice-title {
    font-size: 40px;
    text-transform: uppercase;
    color: #53BDFD;
}
.steps-background {
    // background: url("~assets/blog/background.jpg");
}
.steps-flow {
    overflow-y: hidden;
}

.steps-container {
    width: 90%;
}
.step-desc {
    background-image: url("~assets/shapes/step2-background.svg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    padding: 4% 4% 4% 4%;

    align-self: center;
    overflow-wrap: break-word;
        margin-bottom: 1rem;
    @media(min-width: 768px){
        margin-bottom: 2rem;
    }
}
.step-path {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-media {
    text-align: center;
    max-height: 500px;
    object-fit: contain;
    overflow: hidden;
    align-self: center;
    @media(min-width: 768px){
        
    }
}
.step-video {
    max-width: 100%;
    max-height: 100%;
            margin-bottom: 1rem;
    @media(min-width: 768px){
        margin-bottom: 2rem;
    }
}
.step-img {
    max-width: 100%;
    max-height: 100%;
}
.step-title {
    text-transform: uppercase;
    font-size: 2.5rem;
    color: #53BDFD;
}
.step-text {
    color: black;
    font-size: 1.2rem;
    white-space: pre-wrap;
}
.hidden {
    display: none;
}

    .step-next-choices {
       background-color: rgba(255,255,255,0.8)
    }
@media (max-width: 768px) {
    .steps-container {
        width: 95%;
    }
    .step-desc {
        padding: 10% 8% 8% 8%;
        margin: 20px 0 20px;
    }
    .step-video {
        max-height: 180px;
    }
    .step-img {
        max-width: 90%;
        max-height: 90%;
    }
    .step-title {
        font-size: 2rem;
    }
    .step-text {
        font-size: 0.9rem;
        white-space: pre-wrap;
    }
    .steps-choice-edit-button {
        font-size: 1rem;
        padding: 0.25rem 0.75rem 0.25rem;
        margin: 0.5rem;
    }
    .steps-choice-div {

        padding: 1.5rem;
        margin: 0.5rem;

        font-size: 1rem;
    }
    .step-next-choices {
        margin-top: 2rem;
    }
    .steps-flow {
        display: none;
    }
}
