.blogpost__title{
  font-size: 2rem;
  margin: 2rem 0;
  text-align:center;
  font-weight: bold;

  @media(min-width: 768px){
    width: 80%;
    margin: 2rem 10%;
    font-size: 2.5rem;
  }

  @media(min-width: 1024px){
    margin: 2rem 10%;
  }
}

.blogpost-border {
  position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;
}

.blogpost__description {
  font-size: 1rem;
  margin: auto;
  text-align: justify;
  background-color: white;
  width: 100% !important;
  word-wrap: break-word !important;
}
.blog-post-media {
  position: absolute; width: 100%; height: 100%; top: 0; left: 0;
}


.blogpost__media--padding{
  position: relative; padding-bottom: 100%; height: 0; padding-bottom: 56.2493%;
}
.blog-post-video {
  position: absolute; width: 100%; height: 100%; top: 0; left: 0;
}
.text-align-center {
  text-align: center;
}

.blogpost__description figure{
  display: flex;
  flex-direction: column;
  align-items: center;
}

img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.blogpost__description figure.image-style-side{
  max-width:50%;
  float: right;
  margin: 0 0 0 0.5rem;
}

.blogpost__description figcaption{
  background-color:#f2f2f2;
  color:  black;
  font-size: 0.8rem;
  padding: 0.5rem;
  width: 100%;
}

.table table{
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double #b3b3b3;
}

.table table td{
    min-width: 2em;
    padding: .4em;
    border: 1px solid #bfbfbf;
}



.table table th{
    min-width: 2em;
    padding: .4em;
    border: 1px solid #bfbfbf;
    background-color: #f2f2f2;
}

