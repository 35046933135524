@media (min-width: 576px) {
  .card-columns {
    column-count: 1 !important;
  }
}

@media (min-width: 768px) {
  .card-columns {
    column-count: 3 !important;
  }
}
