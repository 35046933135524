.ck-editor__editable {
        min-height: 60vh;
}

figcaption.ck-editor__editable {
        min-height: 0 !important;
}

.blogCreator__header.form-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;

        @media(min-width: 1024px) {
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
        }
}

.blogCreator__photoInput {
        @media(min-width: 1024px) {
                width: 45%;
        }
}

.blogCreator__videoInput {
        @media(min-width: 1024px) {
                width: 45%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
        }
}

.blogCreator__videoInput--input.form-control {
        @media(min-width: 1024px) {
                width: auto;
                width: 100%;
                flex-grow: 1;
        }
}


.blogCreator__paragraph {
        margin-top: 1rem;

        @media(min-width: 1024px) {
                margin-bottom: 0;
                margin-top: 0;
        }
}

.blogCreator__button {
        margin-top: 1rem;

        @media(min-width: 1024px) {
                margin-top: 0.25rem;
        }
}

.blogCreator__editor {
        background-color: theme-color("primary");
        padding: 1rem;
}

.blogCreator__title {
        @media(min-width: 768px) {
                width: 50%;
        }
}

.shapesContainer {
        display: grid;
        width: 100%;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(4, 1fr);
        column-gap: 5px;
        row-gap: 5px;
        justify-content: center;

        @media(min-width: 1024px) {
                grid-template-columns: repeat(7, 1fr);
                grid-template-rows: repeat(3, 1fr);
        }
}

.shapesContainer__picked {
        background-color: rgba(0, 255, 0, 0.1);
}

.createTile__wrapper{
        display: flex;
        align-items: center;
        flex-direction: column;
        @media(min-width: 768px){
                flex-direction: row;
        }
}


.createTile__postWrapper{
        
        width: 70%;
        display: flex;
        justify-content: center;
         margin-top: 1rem;
         @media(min-width: 768px){
                margin: 0 5.5rem;
                margin-top: 0;
        }
        
}

.postCreator__workshop{
        padding: 1rem;
}

.creator__container.card{
        background-color: rgba(255,255,255,0.5) !important;
        border: none !important;
}

.creator__container.card .creator__header{
         border: none !important;
        background-color: rgba(255,255,255,0.5) !important;
}


.creator__container.card .creator__body{
        background-color: rgba(255,255,255,0.5) !important;
}

.creator__container .card{
        background-color: transparent !important;
}

.creator__container .card-body{
        background-color: transparent !important;
}