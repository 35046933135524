.tree__categoryObject{
    position: relative;
    border: 10px solid theme-color("primary");
    margin-bottom: 1rem;
    padding: 10px;
}

.tree__imageCat{
    width: 100%;
}

.tree__imageWrapper{
    position: relative;
}

.tree__btnImg{
    position: absolute;
    right: 0;
}