.offerToApprove__offerImage{
    max-width: 100%;
    max-height: 50vh;
    // object-fit: cover;
}

.offerToApprove__wrapper{
    position: relative;
    display:flex;
    justify-content: center;
    align-items: center;
}

.offerList__logo{
    height: 4em;
    width: 4em;
    margin-right: 0.25rem;
}