// .blogpost-title {
//   font-size: 4vw;
//   width: 70vw;
//   margin: auto;
//   text-align: center;
//   padding-top: 3vw;
//   padding-bottom: 2vw;
// }
// .blogpost-border {
//   width: 70vw;
//   margin: auto;
// }
// .blogpost-description {
//   font-size: 1.5vw;
//   width: 70vw;
//   margin: auto;
//   text-align: justify;
//   background-color: white;
// }

.apply-for-workshop-button {
  background-color: transparent;
  border: none;

  background-image: url("~assets/buttons/apply-for-workshop-button.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  align-items: center;

  color: white;
  width: 240px;
  height: 150px;
  font-size: 1.5rem;
  margin-top: 20px;
}
.apply-for-workshop-button:hover {
  background-image: url("~assets/buttons/apply-for-workshop-button-hover.svg");
  background-color: transparent;
  border: none;
}