.blog-topic {
  font-size: 3rem;
  justify-content: center;
  margin-top: 3rem;
  color: #666666;
  text-transform: uppercase;
}
.blog-tiles {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  justify-items: center;
  overflow-wrap: break-word;
  margin-top:1rem;
  gap: 1rem;
  @media(min-width:768px){
    margin-top: 3rem;
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media(min-width:1024px){
    margin-top: 4rem;
  }
}

.blog-tile {
  width: 80%;
  border: none;
  color: black;
}

@media(max-width:767px){
  .blog-tile:nth-child(2n + 1){
      justify-self: start;
  }
  
  .blog-tile:nth-child(2n){
      justify-self: end;
  }
}

@media(min-width:768px){
  .blog-tile:nth-child(3n + 1){
    justify-self: start;
  }

  .blog-tile:nth-child(3n){
    justify-self: end;
  }
}

@media (max-width: 576px) {
  .blog-tiles {
    margin: 3vw 2vw 0;
  }
  .blog-tile {
    width: 40vw;
    margin: 1vw 3vw 1vw;
  }
  .blog-topic {
    font-size: 10vw;
    margin: 1vw 0 1vw;
    padding: 0 5vw;
  }
}