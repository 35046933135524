.react-pdf__Document.CVCorrection__pdf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.CVCorrection__form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.cvCorrection__pdfContainer {
  width: 100%;
}

.react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.react-pdf__message--error {
  width: 100%;
}

.react-pdf__message--loading {
  width: 100%;
}

.cvCorrection__pdfLink {
  margin-bottom: 1rem;
}

@media (min-width: 1280px) {
  .cvCorrection__pdfContainer {
    width: 60%;
    padding-right: 1.25rem;
  }

  .cvCorrection__pdfLink {
    margin-bottom: 0;
  }
}
