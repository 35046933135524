.myOffersPerson__container{
    display: flex;
    flex-direction: column;
    
    @media(min-width: 768px){
        align-items: center;
        flex-direction: row;
        justify-content: end;
    }
}

.myOffersPerson__buttonContainer{
    display: flex;
    flex-direction: column;

      @media(min-width: 1024px){
        align-items: center;
        flex-direction: row;
        justify-content: end;
    }
}