.calendar__tilesContainer{
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr)); 
    grid-template-rows: auto;
    max-width: 100%;
}

.calendar__tilesContainer--days{
    border-left: 1px solid #e6e6e6;
    border-top: 1px solid #e6e6e6;
    background-color: rgba(155, 182, 208,0.5);
    &:hover{
        cursor: pointer;
    }
}

.calendar__tilesContainer--dayNames > div{
    border-right: 1px solid #e6e6e6
}

.calendar__tilesContainer--dayNames > div:nth-child(7){
    border-right: none;
}

.calendar__label{
    width: 100% !important;
    font-weight: lighter !important;
    padding:0.3rem;
    color: black !important;
    overflow: hidden;
    white-space: nowrap;
    text-align: start;
    @media(min-width:768px){

    }
}

.calendar__label--view{
    width: auto !important;
}

.calendar__tileDayName{
    padding: 0.5rem;
    width: 100%;
    background-color: #d6d6d6;
}

.calendar__tile{
    min-height: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    @media(min-width:768px){
        padding: 0.5rem;

    }
}

.calendarView__label{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;

}

.calendarView__labelList__item{
    font-size: 1.5rem;
    width: auto;
    padding: 0.5rem;
    display: inline-block;
    border-radius: 5px;
    margin-top: 0.5rem;
}

.calendar__number{
    width: 100%;
    margin-bottom: 0;
    text-align: center;
}

.calendarView__event{
    margin-bottom: 1rem;
    border-left: 1px solid black;
    padding-left: 0.5rem;
}

