.ask-expert-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 2rem;
}

.ask-expert-pic {
  width: 100px;
  height: 100px;
  @media(max-width: 767px){
    height: 80px;
    width: 80px;
  }

}
.ask-expert-pic-col {
  max-width: max-content;
  max-height: 100px;
  width: 100px;
  margin-right: 1rem;

  @media(max-width: 767px){
    height: 80px;
    width: 80px;
  }
  // margin-right: 0.7vw;
  // margin-bottom: 0.5vw;
}
.ask-expert-info {
  display: flex;
  flex-direction: column;
  margin-left: 3vw;
  max-width: 45vw;
}
.ask-expert-video-col {
  width: 500px;
  height:300px;
  align-items: center;
  align-self:center;
}

.ask-expert-video{
  max-width: 500px;
  max-height:300px;
}

.ask-expert-info-role {
  font-size: 2rem;
}
.ask-expert-info-name {
  font-size: 1.5rem;
}
.ask-expert-info-presentation {
  font-size: 1rem;
  margin-bottom: 0.5vw;
  text-align: justify;
}
.ask-expert-info-button {
  display: flex;
  flex-direction: row;
  font-size: 2rem;
  font-weight: bolder;
  width: 20vw;
  height: 5vw;
  margin-top: 1vw;
}
.ask-expert-info-button-img {
  width: 4rem;
  height: 4rem;
  margin-right: 2vw;
}
.ask-expert-button-text {
  font-size: 2.5rem;
}
.ask-expert-link:hover {
  cursor: pointer;
  color: #1D3396;
}

@media(min-width: 1024px){
  .ask-expert-info-col {
    margin-right: 3rem;
    width: calc(100% - 500px - 3rem);
  }
}

.askExpert__userIcon{
  font-size: 4rem;
  max-height: 100px;
  max-width: 100px;
}

@media (max-width: 1023px) {
  .ask-expert-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 1rem;
    margin-bottom: 1.5rem;
  }

  .ask-expert-video{
    max-width: none;
    max-height: none;
  }

  .ask-expert-info-role {
    font-size: 2rem;
  }
  .ask-expert-info-name {
    font-size: 1.5rem;
  }
  .ask-expert-info-presentation {
    font-size: 1rem;
  }
  .ask-expert-button-text {
    font-size: 2rem;
  }
  .ask-expert-video-col {
    position: relative; 
    height: 0; 
    padding-bottom: 56.2493%;
    width: 100%;
    align-self: center;
    // display: contents;
  }
  .ask-expert-link {
    margin: 1rem 0;
    justify-content: center;
  }
  .ask-expert-info-presentation {
    margin-bottom: 0;
  }
  .ask-expert-info-button-img {
    width: 3rem;
    height: 3rem;
    margin-right: 2vw;
  }
  
  .ask-expert-video{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}